import http from '~/utils/http'

export class CommentApi {
  // 取消用户(观看)收藏影片
  static setCanceUserCollection(params: JavCommentLinkParam, opt?: HttpOptions) {
    return http.post<number>({
      ...opt,
      url: '/jav/comment/addCommentLike', // 添加评论点赞记录
      body: params
    })
  }

  // @POST('/jav/comment/addLikeRecord')  //添加点赞记录
  // Future<int> addLikeRecord(@Body() JavRemarkParam param);
  static addLikeRecord(params: JavRemarkParam, opt?: HttpOptions) {
    return http.post<number>({
      ...opt,
      url: '/jav/comment/addLikeRecord',
      body: params
    })
  }

  // @POST('/jav/comment/findCommentByVideoId') //根据影片查询评论
  // Future<CommentRecordResponse> findCommentByVideoId(@Body() VideoReviewParam param);
  static findCommentByVideoId(params: VideoReviewParam, opt?: HttpOptions) {
    return http.post<CommentRecordResponse>({
      ...opt,
      url: '/jav/comment/findCommentByVideoId',
      body: params
    })
  }

  // @POST('/jav/comment/getCommentPageList') //影片评论分页接口
  // Future<CommentRecordResponse> getCommentPageList(@Body() VideoReviewParam param);
  static getCommentPageList(params: VideoReviewParam, opt?: HttpOptions) {
    return http.post<CommentRecordResponse>({
      ...opt,
      url: '/jav/comment/getCommentPageList',
      body: params
    })
  }

  // @POST('/jav/comment/hasCommentLike') //播放器-判断评论是否点赞过当前视频
  // Future<bool> hasCommentLike();
  static hasCommentLike(opt?: HttpOptions) {
    return http.post<boolean>({
      ...opt,
      url: '/jav/comment/hasCommentLike'
    })
  }

  // @POST('/jav/comment/hasLike') //播放器-判断是否点赞过当前视频
  // Future<bool> hasLike(@Body() JavRemarkParam param);
  static hasLike(params: JavRemarkParam, opt?: HttpOptions) {
    return http.post<boolean>({
      ...opt,
      url: '/jav/comment/hasLike',
      body: params
    })
  }

  // @POST('/jav/comment/initCommentLikeCount') //给评论点赞
  // Future<int> initCommentLikeCount(@Body() VideoCommentLikeParam param);
  static initCommentLikeCount(params: VideoCommentLikeParam, opt?: HttpOptions) {
    return http.post<number>({
      ...opt,
      url: '/jav/comment/initCommentLikeCount',
      body: params
    })
  }

  // @POST('/jav/comment/removeCommentLikeRecord') //播放器-删除评论点赞记录
  // Future<bool> removeCommentLikeRecord();
  static removeCommentLikeRecord(opt?: HttpOptions) {
    return http.post<boolean>({
      ...opt,
      url: '/jav/comment/removeCommentLikeRecord'
    })
  }

  // @POST('/jav/comment/removeLikeRecord') //播放器-删除点赞记录
  // Future<bool> removeLikeRecord(@Body() JavRemarkParam param);
  static removeLikeRecord(params: JavRemarkParam, opt?: HttpOptions) {
    return http.post<boolean>({
      ...opt,
      url: '/jav/comment/removeLikeRecord',
      body: params
    })
  }

  // @POST('/jav/comment/submitComment') //提交评论
  // Future<bool> submitComment(@Body() SubmitCommentsParam param);
  static submitComment(params: SubmitCommentsParam, opt?: HttpOptions) {
    return http.post<boolean>({
      ...opt,
      url: '/jav/comment/submitComment',
      body: params
    })
  }

  // @POST('/jav/comment/submitFeedback') //提交反馈
  // Future<JavFeedback> submitFeedback(@Body() FeedbackParam param);
  static submitFeedback(params: FeedbackParam, opt?: HttpOptions) {
    return http.post<JavFeedback>({
      ...opt,
      url: '/jav/comment/submitFeedback',
      body: params
    })
  }

  // @POST('/jav/comment/submitVideoAsk') //提交求片
  // Future<JavVideoAsk> submitVideoAsk(@Body() SeekFilmParam param);
  static submitVideoAsk(params: SeekFilmParam, opt?: HttpOptions) {
    return http.post<JavVideoAsk>({
      ...opt,
      url: '/jav/comment/submitVideoAsk',
      body: params
    })
  }
}
